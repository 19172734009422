@import 'brand_colors';
@import 'globals';

.help-block {
  display: inline !important;
  height: 20px;
}

.save-cancel-top {
  margin-right: -15px;
  float: right;
  padding-bottom: 10px;
  padding-top: 10px;
}

.save-cancel-bottom {
  margin-right: -15px;
  float: right;
  padding-top: 10px;
  padding-bottom: 30px;
}

.form-group {
  position: relative;
}

.form-inline .form-group {
  display: inline;
}

.typeaheadViewDiv {
  min-height: 35px;
}

.typeahead-empty-option {
  font-style: italic;
  color: $brand-dark-grey;
}

.attribute-container {
  padding-bottom: 0.71em;
}

@media (max-width: 767px) {
  .attribute-container {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .form-inline .dataTable .form-control {
    width: 100%;
  }
}

.editor-view {
  margin-bottom: 15px;

  .attribute-container {
    padding-bottom: 20px;
  }
}

.editor-view h1:not(.modal-title), .editor-edit h1:not(.modal-title), .editor-add h1:not(.modal-title) {
  margin-top: 15px;
  margin-bottom: 30px;
}

.editor-view h2, .editor-edit h2, .editor-add h2 {
  margin-top: 5px;
  margin-bottom: 20px;
}

.editor-edit .row-white, .editor-add .row-white {
  padding-top: 30px;
  padding-bottom: 30px;
}

.view-attribute {
  position: relative;
  font-weight: bold;
}

.textarea-attribute-view {
  white-space: pre-wrap;
}

.attribute-instructions {
  background-color: $brand-light-grey;
  color: $brand-black;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 2px;
  padding: 3px;
}

.approval-container {
  align-items: center;
  justify-content: space-between;
  background-color: $brand-light-grey;
  margin-left: -15px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.approval-container-bottom {
  margin-top: 5px;
  margin-bottom: 40px;
}

.approval-container-popup {
  background-color: $brand-white;
}

.approval-status {
  display: inline-table;
  font-family: 'Roboto', sans-serif;
  font-size: calculateEm(14px);
  font-weight: bold;
  padding-left: 0 !important;
}

.approval-status-icon {
  font-size: calculateEm(18px);
  margin-right: 5px;
  margin-left: 0;
}

.approval-legend {
  color: $brand-dark-grey;
  display: inline-table;
  vertical-align: middle;
}

@media (min-width: 768px) {
  .approval-legend {
    padding-top: 10px;
  }
}

@media (min-width: 992px) {
  .approval-legend {
    padding-top: 15px;
  }
}

.approval-btn-group {
  flex-wrap: wrap;
  justify-content: flex-end;
  padding-right: 0;

  .btn {
    flex-grow: 0;
  }
}

.approval-btn-group-bottom {
  padding-top: 5px;
  padding-bottom: 5px;
}

.approval-history-toggle-group {
  padding-right: 0;
}

.modal-container {
  padding: 10px;
}

.approval-pad-row {
  padding-top: 20px;
}

.approval-only-box {
  border: 1px solid $brand-light-grey;
  padding: 15px;
  background: #f9f9f8;
}

.col-form-label-toggle,
.control-checkbox-toggle {
  float: left;
}

.col-form-label, .col-form-label-checkbox .col-form-label-toggle {
  color: $brand-dark-grey !important;
  padding-right: 5px;
  vertical-align: top;
}

.rmp-control-checkbox-toggle {
  float: right;
  margin-right: 20px;
}

.rmp-control-checkbox-label-toggle {
  float: left;
  padding-right: 10px;
  padding-top: 2px;
  font-size: calculateEm(16px);
}

.control-subscript {
  font-style: italic;
  font-size: calculateEm(10px);
  color: $brand-medium-grey;
  text-decoration: underline;
}

.col-form-label-add-or-edit {
  padding-top: 3px;
}

@media screen {
  .col-form-label-tooltip {
    text-decoration: underline;
    text-decoration-color: #aaa; // I know it's not part of our brand, but Simrat & Deepa said it was the right color.
  }
}

@media print {
  .col-form-label, .col-form-label span, .col-form-label-checkbox {
    color: $brand-dark-grey !important;
  }
}

.label-tooltip-guidance {
  float: right;
}

.history-container {
  color: $brand-black;
  margin-left: 5px;
  margin-right: -15px;
}

// Code Purpose: Test Validation Strategy
//
// Breakage Scenario 4.1: This test will be broken by polluting object information with wrong data.
//
// DANGEROUS: Uncomment this to break the TPP print feature intentionally.
//@media print {
//  .history-container {
//    display: none;
//  }
//}

.history-title-left-margin {
  margin-left: 15px;
}

.history-container .col-sm-1,
.history-container .col-sm-2,
.history-container .col-sm-3,
.history-container .col-sm-4,
.history-container .col-sm-5,
.history-container .col-sm-6,
.history-container .col-sm-7,
.history-container .col-sm-8,
.history-container .col-sm-9,
.history-container .col-sm-10,
.history-container .col-sm-11,
.history-container .col-sm-12 {
  background-color: inherit;
  padding-left: 0;
  padding-right: 0;
}

.history-title {
  font-family: 'Roboto', sans-serif;
  font-size: calculateEm(20px);
  font-weight: bold;
  padding: 5px 10px 10px 0;
  margin-left: 0;
  margin-right: 0;
}

.history-filter-control {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 10px;
}

@media print {
  .history-filter-control {
    display: none;
  }
}

.history-item-empty {
  height: calculateRem(40px);
}

.history-item {
  border-left: 2px $brand-white solid;
  font-size: calculateEm(12px);
  padding-top: 5px;
  padding-right: 10px;
  padding-bottom: 5px;
}

.history-item-approved {
  border: solid 2px #d9f3d8;
  border-top: solid 10px #d9f3d8;
  margin-left: -10px;
  padding-left: 10px;
}

.history-item-obsolete {
  border: solid 2px #d9f3d8;
  margin-left: -10px;
  padding-left: 10px;
}

.history-item-selected {
  border-left: 2px $brand-highlight-color solid;
  background-color: #f0f0f0;
  margin-left: -10px;
  padding-left: 10px;
}

.history-updated-by {
  font-style: italic;
  color: black;
}

.history-updated-by-user {
  color: $brand-primary-color;
}

.history-updated-by-state {
  font-weight: bold;
  font-style: normal;
  color: $brand-dark-grey;
}

.history-transitions-container {
  margin-top: 5px;
}

.history-transitions-body {
  background-color: #f8f8f8 !important;
  padding: 10px !important;
}

.history-transitions-header {
  font-family: "Open Sans", sans-serif;
  font-size: calculateEm(11px);
  color: black;
  background-color: #f8f8f8 !important;
  padding-top: 10px;
  padding-bottom: 0 !important;
  padding-left: 15px !important;
}

.history-item-transition {
  font-family: "Open Sans", sans-serif;
  font-size: calculateEm(12px);
  color: black;
  background-color: $brand-white;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 10px;
  padding: 5px;
}

.history-version {
  font-weight: bold;
}

.history-version-hide-transitions,
.history-version-show-transitions {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: calculateEm(18px);
}

.history-cloned-from-label,
.rmp-changed-label,
.library-change-label,
.history-bulk-added-by-label {
  padding-right: 5px;
}

.history-cloned-from,
.library-change,
.history-bulk-added-by {
  padding-left: 5px;
  padding-right: 5px;
}

.history-item-transition-popup-container {
  font-family: "Open Sans", sans-serif;
  font-size: calculateEm(12px);
  color: black;
  padding: 5px;
  background: #f2f3f3;
  margin-bottom: 10px;
}

.history-item-popup-title {
  font-size: calculateEm(13px);
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 5px;
}

.history-item-transition-popup-response.approved {
  color: #386513;
  font-weight: bold;
}

.history-item-transition-popup-response.rejected {
  color: #8f1b1b;
  font-weight: bold;
}

.history-item-transition-popup-response.pending {
  color: black;
  font-weight: bold;
}

.history-item-transition-popup-date {
  color: black;
}

.history-item-popup-comment-title {
  margin-top: 5px;
  font-style: italic;
}

.history-item-popup-comment {
  font-style: italic;
}

.history-item-popup-value {
  margin-left: 10px;
}

.history-item-approver-icons {
  margin-top: 5px;
  margin-bottom: 5px;
}

.history-item-approver-icon {
  border-radius: 50%;
  border: 2px solid $brand-dark-grey;
  padding-left: 0;
  padding-top: 1px;
  padding-right: 0;
  position: relative;
  margin-right: 5px;
  margin-top: 1px;
  margin-bottom: 1px;
  text-align: center;
  display: inline-block;
  width: 28px;
  height: 28px;
}

.history-item-approver-icon-approved {
  border-color: #3aa266;
  background-color: #d9f3d8;
}

.history-item-approver-icon-rejected {
  border-color: #f00;
}

@media print {
  .history-item-selected {
    background-color: #f0f0f0 !important;
  }

  .history-item-transition-popup-date,
  .history-item-transition-popup-response.pending,
  .history-item-transition-popup-container,
  .history-item-transition,
  .history-transitions-header,
  .history-updated-by,
  .history-item-date {
    color: black !important;
  }

  .history-updated-by-user {
    color: $brand-primary-color !important;
  }

  .history-updated-by-state {
    color: $brand-dark-grey !important;
  }

  .history-transitions-header,
  .history-transitions-body {
    background-color: #f8f8f8 !important;
  }

  .history-item-transition {
    background-color: $brand-white !important;
  }

  .history-item-transition-popup-response.approved {
    color: #386513;
  }

  .history-item-transition-popup-response.rejected {
    color: #8f1b1b;
  }

  .history-item-approver-icon-approved {
    background-color: #d9f3d8 !important;
  }
}

.section {
  @include shadow();

  background-color: $brand-white;
  color: $brand-black;
  margin: 0 -15px 20px;
  padding: 15px;

  .section {
    box-shadow: none;
  }
}

.section-non-collapsible-header {
  margin-left: 5px;
}

.section-details-control {
  font-size: calculateRem(18px);
  margin-left: 1px;
  margin-right: 9px;
}

.section-hidden {
  display: none;
}

.section-content {
  margin-left: 20px;
  margin-right: 20px;
}

.section-header-link {
  float: right;
  background: $brand-light-grey;
  font-weight: normal;
  font-size: calculateRem(14px);
  padding: 5px 12px 5px 12px;
  border-radius: 4px;
  height: 25px;
  margin: 0 0 32px 32px;

  a {
    font-weight: 600;
    margin: 0 0 0 5px;
    height: 19px;
  }
}

.diff-inserted {
  color: #f00;
  white-space: pre-wrap;
}

.diff-deleted {
  color: #f00;
  text-decoration: line-through;
  white-space: pre-wrap;
}

.diff-equal {
  color: $brand-black;
  white-space: pre-wrap;
}

@media print {
  .diff-inserted {
    color: #f00 !important;
  }

  .diff-deleted {
    color: #f00 !important;
  }
}

.typeahead-diff-container {
  display: block;
}

.radio-button-container {
  font-weight: normal;
  margin-right: 10px;
}

.table-details-control {
  font-size: calculateEm(18px);
  margin-left: 10px;
}

.table-description {
  display: flex;
  border: 1px solid #e2e2e2;
  padding: 9px;
  font-style: italic;
  font-size: calculateEm(13px);
}

.table-description-info {
  color: $brand-primary-color;
  top: -4px;
  margin-right: 5px;
  font-size: calculateEm(10px);
}

.links-table-link {
  word-break: break-all;
  word-wrap: break-word;
}

.links-header {
  font-weight: bold;
}

.links-btn-group {
  margin-top: 10px;
}

.links-manage-column-header::after {
  display: none !important;
  content: none !important;
}

.invisible-links-manage-column-header::after,
.invisible-links-manage-column-header::before {
  display: none !important;
  content: none !important;
}

.links-manage-column {
  white-space: nowrap;
}

.links-count-label {
  background-color: $brand-light-grey;
  color: #859399;
  cursor: pointer;
  font-size: 12px;
  white-space: nowrap;
}

.links-manage {
  text-align: left;
  max-width: 30em;
  overflow-wrap: break-word;
}

.links-manage-file-upload {
  text-align: right;
}

.links-manage img {
  margin-right: 5px;
}

.links-textarea {
  white-space: pre-wrap;
}

.links-link-diff-text {
  color: #a0a0a0;
}

.chips-list {
  .chip-item {
    border: 1px solid $brand-mild-grey;
    background-color: #f0f4f5;
    display: inline-block;
    padding: 0 3px;
    margin: 2px;
    font-size: 14px;
  }
}

.links-view-link {
  word-break: break-all;
}

.links-diff-container {
  margin-bottom: 10px;
}

.btn-links.btn-sm {
  width: 18px;
  height: 18px;
  padding: 2px 0 0 0 !important;
  font-size: calculateEm(10px);
  line-height: 1.33;
  border-radius: 25px;
}

.btn-links {
  width: 2.14em;
  height: 2.14em;
  text-align: center;
  padding: 1px 4px 0 0 !important;
  margin-left: 5px;
  margin-top: 0 !important;
  margin-bottom: 1px !important;
  font-size: 1em;
  line-height: 1.428571429;
  border-radius: 15px !important;
}

// For Font Awesome buttons
.btn-links .svg-inline--fa {
  margin-left: 5px;
  font-size: 1.1em;
}

.btn-links > .svg-inline--fa.fa-trash-alt {
  margin-left: 4px;
}

.btn-links .svg-inline--fa.fa-undo {
  width: 80%;
}

.btn-links > .svg-inline--fa.fa-xmark:hover {
  color: #b1b2b2;
  cursor: pointer;
}
.links-btn-div {
  margin-top: 15px;
  margin-bottom: 30px;

  &.skeleton {
    width: fit-content;
  }
}

.tech-transfer-process {
  color: $brand-primary-color;
}

.disabled {
  color: $brand-medium-grey;
}

.links-btn-disabled,
.links-btn-disabled:hover {
  color: #b1b2b2;
  cursor: pointer;
}

span.links-btn-disabled {
  font-weight: bold;
}

.btn-links-upload-file {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.btn-links-divider {
  border-left: 1px solid $brand-dark-grey;
  border-right: 1px solid $brand-medium-grey;
  margin-left: 6px;
  margin-right: 3px;
  padding-top: 1px !important;
  padding-bottom: 3px;
}

.links-btn-label-right {
  margin-left: 5px;
}

.links-btn-label-left {
  margin-right: 5px;
}

.links-file-input {
  display: none !important;
}

.links-div-row {
  display: flex;
  margin-bottom: 5px;
}

.links-div-button {
  white-space: nowrap;
}

.links-div-attachment-row {
  height: 36px;
}

.links-div-or-for-upload {
  text-align: right;
  padding-right: 10px;
  width: 100%;
}

.links-vertical-centered {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.links-clip-icon {
  transform: rotate(270deg);
  margin-right: 5px;
}

.links-details-fields-row {
  margin-bottom: 15px;
}

.links-details-fields-row-padding {
  padding-left: 41px;
  padding-right: 7px;
}

.links-details-field {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.links-details-field-label {
  white-space: nowrap;
  margin-bottom: 0;
  font-weight: normal;
}

.links-details-field-label-tooltip {
  margin-bottom: 0;
}

.links-details-row-control {
  padding-left: 3px !important;
  padding-right: 3px !important;
  width: 30px !important;
}

.links-details-row-control .svg-inline--fa {
  padding: 0;
}

.links-table-error-line {
  white-space: pre-wrap;
}

@media print {
  .links-details-row-control {
    width: 30px;
  }
}

.links-details-row {
  border: 0 !important;
}

.links-details-row:hover {
  background-color: $brand-white;
}

.links-details-row-view {
  margin-bottom: 10px;
}

.links-diff span {
  display: block;
}

.no-margins {
  margin: 0 !important;
}

.attribute-with-instructions {
  display: inline-block;
  width: calc(100% - 26px);
}

.field-tooltip-icon {
  color: $brand-primary-color;
  float: right;
  font-size: calculateEm(12px);
  margin-left: 10px;

  svg {
    margin-left: 0;
  }
}

#approvalRequestPopupErrorMessage {
  white-space: pre-line;
}

.base-attribute-value-missing {
  color: #c30;
}

.base-attribute-value-specified {
  color: green;
}

//Fix for react date time picker. There is no direct way to manipulate the auto generated parent control wrappers.
//https://github.com/Hacker0x01/react-datepicker/issues/222
.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker-wrapper {
  width: 100%;
}

// For validation errors from bootstrap-validator and custom ones
.has-error, .custom-has-error .form-control {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-error,
.custom-has-error .help-block,
.custom-has-error .col-form-label,
.custom-has-error .radio,
.custom-has-error .checkbox,
.custom-has-error .radio-inline,
.custom-has-error .checkbox-inline,
.custom-has-error.radio label,
.custom-has-error.checkbox label,
.custom-has-error.radio-inline label,
.custom-has-error.checkbox-inline label {
  color: #a94442;
}

.popup-container .row > div {
  margin-bottom: 15px;
}

.popup-container .row:last-child > div {
  margin-bottom: 0;
}

.propose-date-picker {
  width: 100%;
}

.user-page-heading {
  margin-left: -15px;
  margin-right: -15px;
}

.user-tooltip-dept-container {
  max-height: 500px;
  margin-right: -10px;
  overflow-y: scroll;
}

.user-tooltip-dept {
  margin-bottom: 10px;
  padding-left: 10px;
  text-indent: -10px;
}

.user-tooltip-type {
  background: $brand-light-grey;
  display: inline-block;
  padding: 3px 5px;
  margin-bottom: 3px;
  margin-right: 5px;
  text-indent: 0;
}

.document-btn-margin {
  margin-left: 87px;
}

.document-link {
  display: inline-block;
  padding-top: 3px;
}

.document-upload-group {
  margin-bottom: 0;
}

.document-info-widget {
  outline: 1px solid $brand-slight-grey;
  padding-bottom: 20px;
  padding-top: 20px;
  margin: 0;

  &--has-doc {
    background-color: #F2FBFF;
    outline-color: #C0ECFF;
  }

  &__container {
    display: flex;
    align-items: center;
  }

  &__primary-text {
    display: inline-block;
    font-weight: bold;
    line-height: calculateRem(20px);
    min-width: 120px;
  }

  &__details {
    color: $brand-dark-grey;
    font-size: calculateRem(12px);
    font-weight: 400;
  }
}

.experiment-name {
  font-weight: bold;
  font-size: calculateEm(14px);
}

.experiment-description {
  font-style: italic;
  font-size: calculateEm(12px);
  margin-left: 15px;
  margin-bottom: 15px;
}

.base-json-attribute .dataTables_wrapper {
  overflow-x: auto;
}

.base-json-attribute-error-div {
  margin-top: -25px;
  white-space: pre-wrap;
}

.base-json-attribute-view-error-div {
  margin-top: -10px;
  white-space: pre-wrap;
}

.base-json-attribute-error-input {
  visibility: hidden;
  font-size: 0;
}

.rbt-input-hint-container .rbt-input-hint {
  visibility: hidden !important;
}

.toggle-control-text {
  padding-right: 10px;
}

.user-projects-admin-access-notification {
  font-style: italic;
}

.admin-privileges-superscript {
  font-style: italic;
}

.rmp-comparison-widget {
  border: solid $brand-mild-grey 1px;
  border-radius: 5px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  min-width: 200px;

  .rmp-comparison-widget-less-details {
    padding-bottom: 10px;
  }

  .nav-tabs {
    border-bottom: none;
    font-size: calculateRem(16px);

    > li > a.active {
      @include navBarSelected();
    }

    > li > a {
      @include navBarItem();

      margin-left: 10px;
      margin-right: 10px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.rmp-comparison-widget-title {
  font-style: italic;
}

.rmp-risk-comparison-widget-header {
  margin-bottom: 5px !important;
  padding-left: 0 !important;
}

.rmp-risk-comparison-widget-title {
  font-weight: bold;
  margin-bottom: 2px !important;
  padding-left: 0 !important;
}

.rmp-risk-comparison-widget-score-row {
  border: solid #eee 1px;
  margin-bottom: 2px;
}

.rmp-risk-comparison-widget-score-cell {
  text-align: center;
  margin-bottom: 0 !important;
}

.rmp-risk-comparison-widget-score-diff {
  color: red;
}

.process-capability-links-table,
.process-capability-links-table td,
.process-capability-links-table th {
  border: 1px solid $brand-mild-grey;
  text-align: left;
}

.process-capability-links-table th {
  background-color: $brand-light-grey;
}

.process-capability-links-table {
  border-collapse: collapse;
  width: 100%;
}

.process-capability-links-table th,
.process-capability-links-table td {
  padding: 5px 5px 5px 5px;
}

.process-capability-links-note {
  margin-top: 5px;
}

.process-capability-links-label-link {
  display: inline-block;
  float: right;
}

.process-capability-links-instructions,
.links-instructions {
  float: none;
  margin-left: 2px;
}

.doc-links-table > thead > tr > th,
.doc-links-table > thead > tr > td,
.doc-links-table > tbody > tr > th,
.doc-links-table > tbody > tr > td {
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
}

.doc-links-table > thead > tr {
  background-color: $brand-light-grey !important;
}

.copy-record-popup {
  font-size: calculateEm(14px);
}

.copy-record-popup-container {
  margin-left: 15px;
  margin-right: 15px;
}

.copy-record-popup-indented-plugin {
  margin-top: -10px;
  margin-left: 4px;
  padding-right: 0;
}

.copy-record-popup-disabled-plugin {
  font-style: italic;
}

.copy-checkbox-plugin-warning {
  margin-top: 5px;
}

.json-attribute-checkbox {
  text-align: center;
}

.approved-suppliers-list-margin-top,
.risk-management-plan-report-margin-top,
.user-page-api-keys-margin-top, {
  margin-top: 25px;
}

.material-side-panel-nav-bar {
  margin-right: -15px;
  margin-left: 5px;
}

.history-panel {
  padding-left: 15px;
  padding-right: 0;
  padding-top: 0;
}

.history-panel-title {
  font-family: 'Roboto', sans-serif;
  font-size: calculateEm(14px);
  font-weight: bold;
  padding: 0 10px 10px;
  margin-left: 0;
  margin-right: 0;
}

.base-table-row {
  cursor: pointer;
}

.coa-records-title,
.related-records-title {
  font-family: 'Roboto', sans-serif;
  font-size: calculateEm(14px);
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.coa-add-button,
.related-record-add-button {
  font-size: calculateEm(12px);
  padding-top: 4px;
  padding-bottom: 4px;
  margin-top: 0;
  margin-bottom: 0;
}

.related-batch-import-link {
  margin: 5px 10px;
}

.related-batch-import-date {
  color: $brand-dark-grey;
}

/* stylelint-disable no-descending-specificity */
.related-records-table-column {
  border-top: 1px solid $brand-mild-grey;

  a {
    font-weight: 600;
    font-size: 14px;
  }

  .key {
    font-size: 12px;
    font-weight: 400;
    color: #434952;
  }

  .icons {
    float: right;

    .link {
      color: #859099;
    }
  }

  .icon-labels {
    display: none;
  }
}

/* stylelint-enable no-descending-specificity */

.coa-imports-container,
.data-import-menu-options-container,
.related-records-container {
  color: $brand-black;
  margin-right: -15px;
  margin-left: 0;
  padding-left: 15px;
  padding-right: 30px;
  padding-top: 0;

  .compareLink {
    font-size: 12px;
    color: #1F46A1;
    line-height: 16px;
    font-weight: 600;
    margin-top: 10px;
    display: block;
  }

  .dataTables_wrapper {
    border: 1px solid #c3c3c2;

    .coa-records-table,
    .related-records-table {
      font-size: calculateEm(12px);
      border: none;
      min-width: 250px;
    }

    .dataTables_scrollHead {
      height: 0;
      display: none;
    }

    $table-row-height: 64px;
    $table-bottom-border-height: 2px;

    .skeleton.skeleton-table {
      min-height: ($table-row-height * 2) + $table-bottom-border-height;

      &::after {
        margin-top: 0;
      }
    }
  }
}

table.coa-records-table,
table.related-records-table {
  thead {
    display: none;
  }
}

.related-dnd-records-container {
  margin: 0 15px;
}

.related-dnd-records-sortable {
  border: 1px solid $brand-mild-grey;
  padding: 18px 20px;
  margin-bottom: 5px;
  background: $brand-white;

  &:hover {
    background: $brand-light-grey;
  }

  .svg-inline--fa {
    color: $brand-medium-grey;
  }
}

.related-tab-nav-margin {
  margin-left: 15px;
  margin-right: 15px;
}

.base-approval-editor-top-margin,
.history-container-top-margin {
  margin-top: 15px;
  margin-bottom: 15px;
}

.document-empty-state {
  margin-top: 50px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  height: 670px;

  .document-empty-state-header {
    display: flex;
    justify-content: space-between;

    .document-empty-state-title {
      font-family: 'Roboto', sans-serif;
      font-size: calculateEm(18px);
      font-weight: bold;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .document-empty-state-body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-self: center;
    text-align: center;
    align-items: center;
    height: 440px;
    font-family: "Open Sans", sans-serif;

    .document-empty-state-body-main-text {
      font-size: calculateEm(36px);
      width: 280px;
    }

    .document-empty-state-body-sub-text {
      font-size: calculateEm(14px);
      width: 235px;
    }
  }

  .document-empty-state-arrow {
    height: 260px;
    position: absolute;
    margin-top: 50px;
    margin-left: 685px;
    width: 335px;

    @media (max-width: 1200px) {
      display: none;
    }
  }
}

.document-bulk-add-warning-body {
  max-height: 120px;
  overflow: auto;
}

.document-bulk-add-modal-no-padding-bottom {
  padding-bottom: 0;
}

.document-bulk-add-required {
  color: red;
}

.document-bulk-add-modal-footer-text {
  flex-grow: 1;
  margin-top: 15px;
  font-size: calculateEm(14px);
}

.document-bulk-add-table-cancel-link-cell {
  text-align: center;
}

.document-bulk-add-table-cancel-link {
  font-weight: normal !important;
}

.document-bulk-add-title {
  display: inline-block;
}

.document-bulk-add-title-minimize {
  position: absolute;
  right: 0;
  height: 50px;
  width: 350px;
}

.document-bulk-add-title-maximize {
  height: auto;
  width: 100%;
}

.document-bulk-add-title-minimize-icon {
  float: right;
  display: inline-block;
  margin-top: 5px;
  cursor: pointer;
}

.document-bulk-add-validation-text {
  color: red;
  margin-top: 15px;
}

.document-bulk-add-error-alert {
  margin-left: 0;
  margin-right: 0;
}

.document-bulk-add-validation-icon {
  position: relative;
  float: right;
  top: -18px;
}

.document-bulk-add-validation-icon-hidden {
  visibility: hidden;
}

.document-bulk-add-validation-editor-input {
  position: relative;
  top: 9px;
}

.document-bulk-add-custom-input {
  position: relative;
  top: 6px;
}

.document-bulk-add-progress-bar-div {
  width: 150px;
  display: inline-block;
}

.document-bulk-add-split-button-div {
  float: right;
  margin-top: 5px;
}

.document-bulk-add-split-button {
  margin-left: 10px;
  margin-top: -5px;
}

.control-method-header {
  margin-left: 15px;
}

.splash-screen-left-panel {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;

  .splash-left-panel-main-text {
    font-size: 5em;
    color: rgb(23, 43, 77);
    text-align: left;
    padding-left: 40px;
  }

  .splash-left-panel-secondary-text {
    font-size: 1.1em;
    text-align: left;
    margin-top: 40px;
    padding-right: 70px;
    color: rgb(23, 43, 77);
    padding-left: 40px;
  }

  .splash-screen-button {
    margin-top: 100px;
    width: 300px;
    height: 100px;
    font-size: calculateEm(32px);
    margin-left: -15px;
  }
}

.splash-screen-right-panel {
  background: $brand-light-grey;
  margin-top: 15px;
  margin-bottom: 15px;
  border: 1px solid black;

  .splash-right-panel-main-text {
    font-weight: bold;
    font-size: 1.5em;
    text-align: center;
    margin-top: 40px;
    color: rgb(23, 43, 77);
  }

  .splash-screen-users-icon {
    font-size: 18em;
    color: $brand-dark-blue;
    text-align: center;
  }

  .splash-right-panel-secondary-text {
    color: rgb(23, 43, 77);
    font-size: 1.3em;
    text-align: left;
    margin-bottom: 40px;
  }
}

.upgrade-now-banner-container {
  text-align: center;
  background: $brand-mild-grey;
  margin-top: 20px;
  margin-bottom: 50px;
  padding-top: 10px;
  padding-bottom: 10px;

  .upgrade-now-banner-container-text {
    font-size: calculateEm(16px);
    margin-right: 40px;
  }

  .upgrade-now-banner-container-button {
    width: 200px !important;
    font-size: calculateEm(16px);
  }
}

.info-tooltip-content {
  .info-tooltip-title {
    font-family: 'Roboto', sans-serif;
    font-size: calculateEm(18px);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $brand-black;
  }

  .info-tooltip-subtitle {
    font-family: 'OpenSans', sans-serif;
    font-size: calculateEm(12px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0;
    margin-top: 2px;
    margin-bottom: 13px;
    color: $brand-dark-grey;
  }
}

.approval-popup-error-div {
  margin-right: 0;
  margin-left: 0;
}

.general-attribute-container {
  margin-top: 20px;
}

.product-sub-top-tab,
.process-sub-top-tab {
  background: $brand-light-grey;
  border-bottom: solid 1px $brand-mild-grey;
  padding-left: 60px;
}

.process-top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
}

.product-sub-top-nav {
  display: inline-flex;
  align-items: center;
}

.product-filter-dropdown {
  margin-top: 5px;
  margin-right: 45px;
}

.sub-top-nav {
  display: inline-block;
  margin-left: -15px !important;
  margin-right: -5px !important;
  margin-bottom: 0 !important;
}

.sub-top-nav-selected {
  font-weight: bold;
  padding: 7px;
  border-radius: 4px;
  background-color: #dadada;
}

.product-settings-bar {
  margin-bottom: 15px;
  margin-left: 45px;
  margin-right: 45px;
}

.product-search-box {
  margin-left: -15px;
}

.product-buttons-container {
  margin-right: -30px;
  margin-bottom: 0;
  padding-top: 28px;
  float: right;
}

#productPage #bodyDiv {
  background: white !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.product-error-bar {
  margin-left: 0;
  margin-right: -30px;
}

// For the flyout panel
.flyout-panel {
  color: black;
  height: 100%;
  margin-top: 4px;
  min-height: 250px;
  width: 100%;
}

/* stylelint-disable no-descending-specificity */
.flyout-panel > div:last-child {
  display: flex;
  width: 100%;
  align-items: stretch;
  min-height: 250px;
  height: calc(100% - 4px);
}

/* stylelint-enable no-descending-specificity */

.flyout-left-panel {
  min-height: 250px;
  display: flex;
  flex-direction: column;

  &.with-animation {
    transition: width 1s ease;
  }
}

.flyout-right-panel {
  min-height: 250px;
  position: relative;

  &.with-animation {
    transition: width 1s ease;
  }
}

/**
 * QI-4083 Disable printing the quick panel
 */
@media print {
  .flyout-right-panel, .gutter {
    display: none;
  }
}

.full-width {
  width: 100%;
}

.product-tables-actions-container {
  display: inline-block;
  float: right;
  margin-right: 60px;
  margin-top: 10px;
}

.product-tables-add-button,
.process-tables-add-button {
  height: 33px;
  padding-top: 5px;
  margin-top: 5px;
}

.process-tables-add-button {
  margin-left: 10px;
}

.product-export-button {
  margin: 0 0 4px 10px;
  padding-top: 5px;
}

.section-content h3 {
  margin-top: 2em;
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.attribute-action-button {
  /* There is no way to fix this without breaking other stuff. */
  /* stylelint-disable no-descending-specificity */
  label {
    cursor: pointer;
  }
}

#projectChangeRMPPage {
  .rmp-risk-comparison-widget-score-dropdown {
    padding-left: 0;
    padding-right: 15px;

    button {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      white-space: nowrap;
      text-align: left;
      margin-top: 8px;
      margin-bottom: 8px;

      border-color: #cccccc;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .rmp-risk-comparison-widget-score-row {
    margin-bottom: 0;
  }

  .rmp-risk-comparison-widget-score-cell {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: left;

    .score {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .col-form-label {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }

  .rmp-risk-comparison-widget-header-row {
    border: 1px solid #eeeeee;

    .new {
      padding-left: 0;
    }
  }

  .rmp-risk-comparison-widget-header {
    margin-bottom: 0 !important;
    padding-left: 15px !important;
  }

  .rmp-risk-comparison-widget-title {
    margin-bottom: 15px !important;
    margin-top: 20px;
  }

  .rmp-comparison-widget {
    .nav-tabs {
      margin-top: 15px;

      .nav-link {
        color: #000000 !important;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  #rmpConversionMatrixWidget {
    border: none;
  }

  .tab-content {
    border: 1px solid #dee2e6;
  }

  .tab-content.single-tab {
    border: none;
  }

  .rmp-requirement-risk-tab {
    padding-left: 15px;
    padding-right: 15px;
  }

  .risk-item {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    border-bottom: 1px solid #dee2e6;
    padding-top: 15px;

    .box {
      display: inline-block;
      width: 10px;
      height: 10px;
    }

    p {
      font-size: 12px;
      line-height: 16px;
    }
  }
}

#compendialStandardTypeahead {
  .form-control[disabled] {
    background-color: #e9ecef !important;
  }
}